<template>
  <div>
    <!-- 按钮列表 -->
    <div class="btnList" ref="btnList">
      <el-button class="itemBtn btnColor" @click="addMeunBtn_1"
        >新建一级菜单</el-button
      >
      <el-button class="itemBtn btnColor" @click="toggleRowExpansion(isShow)">{{
        allShow
      }}</el-button>
    </div>
    <!-- 菜单管理 -->
    <el-table
      :data="Meundata"
      ref="dataTreeList"
      height="84vh"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        prop="menuName"
        label="菜单名称"
        sortable
        min-width="160"
      >
      </el-table-column>
      <el-table-column
        prop="parentName"
        label="上级菜单"
        sortable
        min-width="120"
      >
      </el-table-column>
      <el-table-column prop="number" label="菜单编号" min-width="120">
      </el-table-column>
      <el-table-column prop="sort" sortable label="排序值" width="80">
      </el-table-column>
      <el-table-column prop="jumpUrl" label="跳转路径" min-width="120">
      </el-table-column>
      <el-table-column prop="isCustom" label="是否定制" width="80">
      </el-table-column>
      <el-table-column prop="number" fixed="right" label="操作" min-width="280">
        <template slot-scope="scope">
          <el-button
            class="editBtns"
            type="text"
            @click="itemClick(scope.row, 2)"
            >编辑</el-button
          >
          <el-button
            class="editBtns"
            v-if="scope.row._level != 3"
            type="text"
            @click="itemClick(scope.row, 3)"
            >添加子菜单</el-button
          >
          <el-button
            class="editBtns"
            type="text"
            @click="itemClick(scope.row, 4)"
            >删除菜单</el-button
          >
          <el-button
            class="editBtns"
            type="text"
            @click="itemClick(scope.row, 5)"
            >权限设置</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 菜单 -->
    <div>
      <el-dialog
        :title="diaLogTitle"
        :visible.sync="addDialog"
        width="40%"
        @close="handleClose"
      >
        <div>
          <el-form label-position="right" label-width="120px" :model="formData">
            <el-form-item label="菜单编号">
              <el-input
                :disabled="diaLogTitle == '编辑菜单'"
                v-model="formData.number"
              ></el-input>
            </el-form-item>
            <el-form-item label="菜单名称">
              <el-input v-model="formData.menuName"></el-input>
            </el-form-item>
            <el-form-item label="上级菜单">
              <el-select
                :disabled="isable"
                v-model="formData.parentId"
                placeholder="请选择"
                filterable
                clearable
              >
                <el-option
                  v-for="item in parentMenuList"
                  :key="item.id + ''"
                  :label="item.menuName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否定制">
              <el-select
                @change="changeIsCustom"
                v-model="formData.isCustom"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in isCustomList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formData.isCustom == 1" label="有权限的租户">
              <!-- <el-input v-model="formData.tenantId"></el-input> -->
              <!-- zuHuList -->
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in zuHuList"
                  :key="index"
                  :label="item.tenantId"
                  >{{ item.tenantName }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="排序">
              <el-input v-model="formData.sort"></el-input>
            </el-form-item>
            <el-form-item label="跳转路径">
              <el-input v-model="formData.jumpUrl"></el-input>
            </el-form-item>
            <el-form-item label="图标路径">
              <el-input v-model="formData.icon"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancleBtn">取 消</el-button>
          <el-button type="primary" @click="addMeunSave">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 关联权限 -->
    <div>
      <el-dialog
        title="关联权限"
        :visible.sync="quanxianDialog"
        width="46%"
        @close="handleClose_qx"
      >
        <div>
          <div style="font-size: 15px; font-weight: bold; margin-bottom: 20px">
            当前菜单: {{ checkMenuName }}
          </div>
          <div v-if="meunPermissionList.length > 0">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="checkedCities"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                style="margin: 5px 20px"
                v-for="(item, index) in meunPermissionList"
                :label="item.permissionId"
                :key="index"
                >{{ item.permissionName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div v-else>暂无权限可选</div>
          <el-form label-position="right" label-width="120">
            <el-form-item label="手动添加权限:">
              <el-input
                placeholder="请输入权限id"
                v-model="otherPermissionId"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="cancleBtn_qx">取 消</el-button> -->
          <el-button type="primary" @click="addMeunSave_qx">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import treeTable from "../components/treeTable/treeTable";
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import mathNum from "@/assets/js/MathNumBer";
export default {
  inject: ["reload"],
  name: "LoginForm",
  data() {
    return {
      addDialog: false,
      quanxianDialog: false,
      diaLogTitle: "新增菜单",
      formData: {
        menuName: "",
        parentId: "",
        number: "",
        sort: 1,
        jumpUrl: "",
        isCustom: "0",
        icon: "",
      },
      parentMenuList: [],
      isCustomList: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      columns: [
        {
          text: "菜单名称",
          value: "menuName",
        },
        {
          text: "上级菜单",
          value: "parentName",
        },
        {
          text: "菜单编号",
          value: "number",
        },

        {
          text: "排序值",
          value: "sort",
        },
        {
          text: "跳转路径",
          value: "jumpUrl",
        },
        {
          text: "是否定制",
          value: "isCustom",
        },
      ],
      Meundata: [],
      levArr1: [],
      isable: false, //选上级是否禁用
      allShow: "全关闭",
      isShow: false,

      checkAll: false,
      permissionList: [], //全部权限列表
      meunPermissionList: [], //当前菜单权限列表
      checkedCities: [], //已选中的权限列表
      otherPermissionId: "",
      checkMenuId: "", //选中的菜单id
      checkMenuName: "", //选中的菜单
      // isIndeterminate: false, //选择部分
      zuHuList: [], //租户列表
      checkList: [], //已选的租户
      allMenuList: [], //全部菜单
    };
  },
  // components: { treeTable },
  created() {
    this.getSubMenus();
  },
  mounted() {
    this.getPermissionList();
  },
  methods: {
    changeIsCustom(e) {
      console.log(e);
      console.log(this.formData);
      if (e == 1) {
        let mid = "";
        if (this.formData.menuId) {
          mid = this.formData.menuId;
        }
        this.getMenusTenantList(mid);
      }
    },
    // 根据菜单id获取租户
    getMenusTenantList(menuId) {
      Api.getMenusTenantList({ menuId: menuId }).then((res) => {
        console.log(res);
        let result = res.data.result || [];
        this.zuHuList = result;
        this.checkList = [];
        result.forEach((item) => {
          if (item.isCustom == 1) {
            this.checkList.push(item.tenantId);
          }
        });
      });
    },
    getPermissionList() {
      Api.getPermissionList().then((res) => {
        let permissionList = res.data.result || [];
        this.permissionList = permissionList;
      });
    },
    handleCheckAllChange(val) {
      let pArr = [];
      for (var i = 0; i < this.meunPermissionList.length; i++) {
        pArr.push(this.meunPermissionList[i].permissionId);
      }

      this.checkedCities = val ? pArr : [];
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.meunPermissionList.length;
    },

    //切换树形表格展开
    toggleRowExpansion(temp) {
      if (temp) {
        this.allShow = "全关闭";
      } else {
        this.allShow = "全展开";
      }
      this.isShow = !temp;
      this.toggleRowExpansion_forAll(this.Meundata, temp);
    },
    toggleRowExpansion_forAll(data, temp) {
      data.forEach((item) => {
        this.$refs.dataTreeList.toggleRowExpansion(item, temp);
        if (item.children != undefined && item.children != null) {
          this.toggleRowExpansion_forAll(item.children, temp);
        }
      });
    },

    // 表格后面的点击事件
    itemClick(row, type) {
      let param = row;
      console.log(param);
      if (type == 2) {
        //编辑
        var leves = [
          {
            menuName: param.parentName,
            id: param.parentId,
          },
        ];
        this.formData.parentId = param.parentId;
        // this.parentMenuList = leves;
        // this.parentMenuList = this.levArr1;
        this.parentMenuList = this.allMenuList || [];
        this.parentMenuList.push({
          menuName: "无上级",
          id: "-1",
        });
        this.isable = false; //禁用选择上级
        this.updateMenuBtn(param);
      } else if (type == 3) {
        //新增
        var leveAdd = [
          {
            menuName: param.menuName,
            id: param.id,
          },
        ];
        this.formData.parentId = param.id;
        this.parentMenuList = leveAdd;
        this.isable = true; //禁用选择上级
        this.addMeunBtn();
      } else if (type == 4) {
        // 删除
        let Delparam = {
          sysMenuId: param.id,
        };
        let sign = tools.getSign(Delparam);
        Delparam.sign = sign;
        this.$confirm("是否删除 " + row.menuName + " 菜单?", "提示:")
          .then(() => {
            Api.delMenus(Delparam).then((res) => {
              this.$message.success(res.data.message || "删除成功");
              this.getSubMenus();
            });
          })
          .catch(() => {});
      } else if (type == 5) {
        this.checkMenuName = row.menuName;
        this.checkMenuId = row.id;
        // 关联权限
        this.quanxianDialog = true;
        Api.getMenusPermissionList({ menuId: row.id }).then((res) => {
          let pmList = res.data.result || [];
          let arrs1 = [];
          pmList.forEach((item) => {
            arrs1.push(item.permissionId);
          });
          this.checkedCities = arrs1;
          let pArrs = JSON.parse(JSON.stringify(this.permissionList)) || [];
          let meunArrP = [];
          for (var a = 0; a < pArrs.length; a++) {
            let strs = pArrs[a].name || "";
            let strArr = strs.split(":");
            if (strArr.length > 0 && strArr[0]) {
              let qxname = strArr[0];
              if (qxname == row.number) {
                meunArrP.push(pArrs[a]);
              }
            }
          }
          this.meunPermissionList = meunArrP;
          if (meunArrP.length == this.checkedCities.length) {
            this.checkAll = true;
          }
          // number
        });
      }
    },
    updateMenu() {
      Api.updateMenu().then();
    },
    // 获取菜单列表
    getSubMenus() {
      Api.getSubMenus().then((res) => {
        let datas = res.data.result || [];
        this.allMenuList = datas || [];
        let arr = [
          {
            menuName: "无上级",
            id: "-1",
          },
        ];
        for (var i = 0; i < datas.length; i++) {
          if (datas[i].parentId == -1) {
            arr.push(datas[i]);
          }
        }
        this.levArr1 = arr; //一级菜单
        this.diGuiGetMeun(datas, "-1", "无上级");
      });
    },
    //递归获取数据
    diGuiGetMeun(data, parentId, parentName) {
      let tree = [];
      let temp;
      let leve = 1;
      data.forEach((item) => {
        if (item.parentId == parentId) {
          let obj = item;
          obj.parentName = parentName;
          obj.leve = leve;
          temp = this.diGuiGetMeun(data, item.id, item.menuName);
          if (temp.length > 0) {
            obj.children = temp;
          }
          tree.push(obj);
        }
      });
      //排序
      for (var i = 0; i < tree.length - 1; i++) {
        if (tree[i].sort > tree[i + 1].sort) {
          let temp = tree[i + 1];
          tree[i + 1] = tree[i];
          tree[i] = temp;
        }
      }

      this.Meundata = tree;
      return tree;
    },

    // 编辑菜单
    updateMenuBtn(row) {
      this.addDialog = true;
      // this.formData =row;
      this.formData.menuName = row.menuName;
      this.formData.parentId = row.parentId + "";
      this.formData.number = row.number;
      this.formData.sort = row.sort;
      this.formData.jumpUrl = row.jumpUrl;
      this.formData.isCustom = row.isCustom + "";
      this.formData.menuId = row.id;
      // this.formData = row;
      this.diaLogTitle = "编辑菜单";
      if (row.isCustom == 1) {
        this.getMenusTenantList(row.id);
      }
    },
    // 新增菜单
    addMeunBtn() {
      this.addDialog = true;
      this.diaLogTitle = "新增菜单";
    },
    // 新增一级菜单
    addMeunBtn_1() {
      this.addDialog = true;
      this.isable = false;
      this.diaLogTitle = "新增菜单";
      this.parentMenuList = this.levArr1 || [];
    },
    // 取消按钮
    cancleBtn() {
      this.addDialog = false;
      //刷新
      // this.reload();
    },
    // 取消按钮
    cancleBtn_qx() {
      this.quanxianDialog = false;
      //刷新
      this.reload();
    },
    addMeunSave_qx() {
      let that = this;
      if (this.otherPermissionId) {
        let ids = tools.getOrderNum(this.otherPermissionId);
        this.checkedCities.concat(ids);
      }
      let param = {
        menuId: this.checkMenuId,
        permissionIds: this.checkedCities,
      };
      Api.addMenuPermission(param).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "操作成功");
          setTimeout(() => {
            that.quanxianDialog = false;
          }, 1000);
        }
      });
    },
    // 确认新增
    addMeunSave() {
      // let param = {
      //   isCustom: "", //是否定制0=否1=是(默认0),
      //   menuName: "", //菜单名称
      //   number: "", //菜单编号
      //   parentId: "", //上级菜单id(默认-1)
      //   sort: "", //排序值(默认1)
      //   jumpUrl: "", //菜单跳转url
      //   tenantId: "", //租户id(isCustom=1时必传)
      // };

      let param = this.formData;
      param.tenantIds = this.checkList;

      if (this.diaLogTitle == "新增菜单") {
        Api.addMeun(param).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "添加成功");
            setTimeout(() => {
              this.addDialog = false;
              this.getSubMenus();
            }, 1000);
          }
        });
      } else {
        //编辑
        if (param.id) {
          param.menuId = param.id;
        }

        Api.updateMenu(param).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "修改成功");
            setTimeout(() => {
              this.addDialog = false;
              this.getSubMenus();
            }, 1000);
          }
        });
      }
    },
    handleClose() {
      this.formData = {
        menuName: "",
        parentId: "",
        number: "",
        sort: 1,
        jumpUrl: "",
        isCustom: "0",
      };
    },
    handleClose_qx() {
      this.checkedCities = []; //已选中的权限列表
      this.otherPermissionId = "";
      this.checkMenuId = "";
      this.checkMenuName = "";
      this.checkAll = false;
      //刷新
      // this.reload();
    },
  },
};
</script>

<style scoped lang='scss'>
::v-deep .el-select {
  width: 100%;
}
.editBtns {
  padding: 4px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
}
</style>